import { defineComponent } from 'vue';
import { validator } from '@ts/Util/i18n';
import FacebookIcon from '@bx-icons/logos/bxl-facebook-circle.svg';
import EmailIcon from '@bx-icons/solid/bxs-envelope.svg';
import DownloadIcon from '@bx-icons/regular/bx-download.svg';
import QRCodeIcon from '@bx-icons/regular/bx-qr.svg';
import WhatsappIcon from '@icons/whatsapp.svg';
import { isMobileReactive } from '@ts/Util/responsiveness';
const localT = {};
export default defineComponent({
    name: 'ShareStrip',
    components: {
        FacebookIcon,
        EmailIcon,
        DownloadIcon,
        WhatsappIcon,
        QRCodeIcon,
    },
    props: {
        fbAppId: {
            type: String,
            required: true
        },
        shareData: {
            type: Object,
            default: null
        },
        t: {
            type: Object,
            required: true,
            validator: validator(localT)
        },
    },
    emits: ['share'],
    setup(props, ctx) {
        const isMobile = isMobileReactive();
        const addUtmSource = (url, source) => {
            const parsed = new URL(url);
            parsed.searchParams.append('utm_source', source);
            return parsed.toString();
        };
        const shareFacebook = () => {
            ctx.emit('share', 'facebook');
            const shareUrl = encodeURIComponent(addUtmSource(props.shareData?.url || '', 'facebook'));
            const queryString = `app_id=${props.fbAppId}&display=popup&href=${shareUrl}`;
            const shareLink = `https://www.facebook.com/dialog/share?${queryString}`;
            window.open(shareLink, '_blank');
        };
        const shareEmail = () => {
            ctx.emit('share', 'email');
            const title = `${props.shareData?.title}`;
            const url = encodeURIComponent(`${props.shareData?.url}`);
            const shareLink = `mailto:?subject=${title}&body=${url}`;
            window.open(shareLink, '_blank');
        };
        const shareWhatsapp = () => {
            const title = `${props.shareData?.title}`;
            const url = encodeURIComponent(`${props.shareData?.url}`);
            let shareLink = `https://api.whatsapp.com/send?text=${title}%0A${url}`;
            if (isMobile.value) {
                if (/Android/i.test(navigator.userAgent)) {
                    shareLink = `intent://send?text==${title}%0A${url}#Intent;package=com.whatsapp;action=android.intent.action.SEND;type=text/plain;end`;
                }
                else if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
                    shareLink = `whatsapp://send?text=${title}%0A${url}`;
                }
            }
            window.open(shareLink);
        };
        const showQRCode = () => {
            ctx.emit('share', 'qr');
        };
        return {
            isMobile,
            shareFacebook,
            shareEmail,
            shareWhatsapp,
            showQRCode,
        };
    }
});
