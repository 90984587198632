import { defineComponent, ref } from 'vue';
import { validator } from '@ts/Util/i18n';
import Modal from '@components/Core/Modal.vue';
import CopyTextInput from '@components/Core/CopyTextInput.vue';
import ShareStrip from "@components/Sharing/ShareStrip.vue";
import QRCodeModal from '@components/Sharing/QRCodeModal.vue';
const localT = {
    shareableModalTitle: 'Share link or download',
    shareLink: 'Share link',
};
export default defineComponent({
    name: 'ShareModal',
    components: {
        Modal,
        CopyTextInput,
        QRCodeModal,
        ShareStrip,
    },
    props: {
        t: {
            type: Object,
            required: true,
            validate: validator(localT)
        },
        show: {
            type: Boolean,
            default: false,
        },
        shareData: {
            type: Object,
            default: null
        },
        classData: {
            type: Object,
            default: (() => null),
        },
        instructorData: {
            type: Object,
            default: (() => null),
        },
        shareType: {
            type: String,
            required: true
        },
        fbAppId: {
            type: String,
            default: '',
        },
        simplified: {
            type: Boolean,
            default: false,
        }
    },
    emits: ['close', 'share'],
    setup(props, ctx) {
        const showQRModal = ref(false);
        const handleShared = (type) => {
            if (type === 'qr') {
                ctx.emit('close');
                showQRModal.value = true;
            }
        };
        return {
            showQRModal,
            handleShared
        };
    }
});
