import { defineComponent } from 'vue';
import CopyIcon from '@bx-icons/regular/bx-copy.svg';
/**
 * Component that adds an "input like" box showing the URL with a built in copy button that copies text to the clipboard
 */
export default defineComponent({
    name: 'CopyTextInput',
    components: {
        CopyIcon,
    },
    props: {
        copyText: {
            type: String,
            required: true,
        },
        label: {
            type: String,
            default: '',
        },
        copiedT: {
            type: String,
            default: 'Copied!',
        }
    },
    emits: ['copied'],
    data: () => {
        return {
            copying: false,
        };
    },
    methods: {
        copy() {
            navigator.clipboard.writeText(this.copyText);
            this.copying = true;
            this.$emit('copied');
            setTimeout(() => this.copying = false, 3500);
        },
    }
});
