import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
    key: 0,
    class: "share-strip-items"
};
const _hoisted_2 = {
    key: 0,
    class: "share"
};
const _hoisted_3 = {
    key: 1,
    class: "share"
};
const _hoisted_4 = {
    key: 2,
    class: "share"
};
const _hoisted_5 = ["href", "download"];
const _hoisted_6 = {
    key: 4,
    class: "share"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_QRCodeIcon = _resolveComponent("QRCodeIcon");
    const _component_FacebookIcon = _resolveComponent("FacebookIcon");
    const _component_EmailIcon = _resolveComponent("EmailIcon");
    const _component_DownloadIcon = _resolveComponent("DownloadIcon");
    const _component_WhatsappIcon = _resolveComponent("WhatsappIcon");
    return (_ctx.shareData)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            (_ctx.shareData.qrSRC)
                ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                    _createVNode(_component_QRCodeIcon, {
                        class: "share-icon",
                        onClick: _ctx.showQRCode
                    }, null, 8, ["onClick"])
                ]))
                : _createCommentVNode("", true),
            (_ctx.shareData)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    _createVNode(_component_FacebookIcon, {
                        class: "share-icon",
                        onClick: _ctx.shareFacebook
                    }, null, 8, ["onClick"])
                ]))
                : _createCommentVNode("", true),
            (_ctx.shareData)
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    _createVNode(_component_EmailIcon, {
                        class: "share-icon",
                        onClick: _ctx.shareEmail
                    }, null, 8, ["onClick"])
                ]))
                : _createCommentVNode("", true),
            (_ctx.shareData.downloadableURL)
                ? (_openBlock(), _createElementBlock("a", {
                    key: 3,
                    href: _ctx.shareData.downloadableURL,
                    download: _ctx.shareData.downloadableFilename
                }, [
                    _createVNode(_component_DownloadIcon, { class: "share-icon" })
                ], 8, _hoisted_5))
                : _createCommentVNode("", true),
            (_ctx.isMobile)
                ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                    _createVNode(_component_WhatsappIcon, {
                        class: "share-icon",
                        onClick: _ctx.shareWhatsapp
                    }, null, 8, ["onClick"])
                ]))
                : _createCommentVNode("", true)
        ]))
        : _createCommentVNode("", true);
}
