import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, withCtx as _withCtx } from "vue";
const _hoisted_1 = { class: "container" };
const _hoisted_2 = { class: "qr-modal-body" };
const _hoisted_3 = {
    key: 0,
    class: "z-b3 f-inter qr-modal-description"
};
const _hoisted_4 = { class: "qr-section" };
const _hoisted_5 = ["src"];
const _hoisted_6 = {
    key: 0,
    class: "details"
};
const _hoisted_7 = { class: "class-title" };
const _hoisted_8 = { class: "z-b3 f-inter" };
const _hoisted_9 = { class: "z-b3 qr-instructions f-inter" };
const _hoisted_10 = {
    key: 1,
    class: "details"
};
const _hoisted_11 = { class: "instructor-title" };
const _hoisted_12 = { class: "z-b3 f-inter" };
const _hoisted_13 = { class: "z-b3 f-inter membership-type-label" };
const _hoisted_14 = { class: "instructor-details-list" };
const _hoisted_15 = {
    key: 0,
    class: "z-b4 f-inter"
};
const _hoisted_16 = {
    key: 1,
    class: "z-b4 f-inter"
};
const _hoisted_17 = { class: "controls-section" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_StartSvg = _resolveComponent("StartSvg");
    const _component_ZButton = _resolveComponent("ZButton");
    const _component_Modal = _resolveComponent("Modal");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_Modal, {
            show: _ctx.show,
            "show-close-button": "",
            "close-when-click-on-background": "",
            "mobile-drawer": "",
            "custom-styles": {
                modal: {
                    'width': '100%',
                    'max-width': '37.375rem'
                }
            },
            onClosed: _cache[2] || (_cache[2] = ($event) => (_ctx.$emit('close')))
        }, {
            default: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("h4", {
                        class: _normalizeClass(["qr-modal-title", { 'qr-modal-instructor-title': _ctx.shareType === _ctx.QRCodeShareType.SHARE_INSTRUCTOR }])
                    }, _toDisplayString(_ctx.modalTitle), 3),
                    (_ctx.shareType === _ctx.QRCodeShareType.SHARE_INSTRUCTOR)
                        ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.t.scanThisQRCodeInstructor), 1))
                        : _createCommentVNode("", true),
                    _createElementVNode("section", _hoisted_4, [
                        _createElementVNode("img", {
                            class: "qr-image",
                            src: _ctx.qrSrc
                        }, null, 8, _hoisted_5),
                        (_ctx.shareType === _ctx.QRCodeShareType.SHARE_CLASS)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                                _createElementVNode("h5", _hoisted_7, _toDisplayString(_ctx.classData.title), 1),
                                _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.classDate), 1),
                                _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.t.scanThisQRCode), 1)
                            ]))
                            : _createCommentVNode("", true),
                        (_ctx.shareType === _ctx.QRCodeShareType.SHARE_INSTRUCTOR)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                                _createElementVNode("h5", _hoisted_11, _toDisplayString(_ctx.instructorData.title), 1),
                                _createElementVNode("p", _hoisted_12, _toDisplayString(_ctx.instructorData.location), 1),
                                _createElementVNode("p", _hoisted_13, _toDisplayString(_ctx.instructorData.membership_type), 1),
                                _createElementVNode("p", null, [
                                    _createElementVNode("ul", _hoisted_14, [
                                        (_ctx.instructorData.rating_score)
                                            ? (_openBlock(), _createElementBlock("li", _hoisted_15, [
                                                _createVNode(_component_StartSvg, { class: "rating-star" }),
                                                _createTextVNode(" " + _toDisplayString(_ctx.instructorData.rating_score), 1)
                                            ]))
                                            : _createCommentVNode("", true),
                                        _cache[3] || (_cache[3] = _createElementVNode("li", { class: "z-b4 f-inter separator" }, " · ", -1)),
                                        (_ctx.instructorData.num_reviews)
                                            ? (_openBlock(), _createElementBlock("li", _hoisted_16, _toDisplayString(_ctx.instructorData.num_reviews) + " " + _toDisplayString(_ctx.t.ratings), 1))
                                            : _createCommentVNode("", true)
                                    ])
                                ])
                            ]))
                            : _createCommentVNode("", true)
                    ]),
                    _createElementVNode("section", _hoisted_17, [
                        _createVNode(_component_ZButton, {
                            onClick: _cache[0] || (_cache[0] = _withModifiers(($event) => (_ctx.printQRCode()), ["prevent"]))
                        }, {
                            default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.t.printQRLabel), 1)
                            ]),
                            _: 1
                        }),
                        _createVNode(_component_ZButton, {
                            class: "cancel-button",
                            variety: "phoenix",
                            onClick: _cache[1] || (_cache[1] = _withModifiers(($event) => (_ctx.$emit('close')), ["prevent"]))
                        }, {
                            default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.t.cancel), 1)
                            ]),
                            _: 1
                        })
                    ])
                ])
            ]),
            _: 1
        }, 8, ["show"])
    ]));
}
