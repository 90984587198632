import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue";
const _hoisted_1 = ["title"];
const _hoisted_2 = {
    key: 0,
    class: "label"
};
const _hoisted_3 = { class: "text-wrapper" };
const _hoisted_4 = { key: 0 };
const _hoisted_5 = { key: 1 };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_copy_icon = _resolveComponent("copy-icon");
    return (_openBlock(), _createElementBlock("div", {
        class: "copy-wrapper",
        title: _ctx.copyText
    }, [
        (_ctx.label.length)
            ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.label), 1))
            : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_3, [
            (_ctx.copying)
                ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.copiedT), 1))
                : (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.copyText), 1))
        ]),
        (!_ctx.copying)
            ? (_openBlock(), _createBlock(_component_copy_icon, {
                key: 1,
                class: "icon",
                onClick: _ctx.copy
            }, null, 8, ["onClick"]))
            : _createCommentVNode("", true)
    ], 8, _hoisted_1));
}
