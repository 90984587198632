import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode } from "vue";
const _hoisted_1 = { class: "share-modal__title" };
const _hoisted_2 = {
    key: 0,
    class: "share-modal__content_image"
};
const _hoisted_3 = ["src"];
const _hoisted_4 = {
    key: 2,
    class: "share-modal__content_actions"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_CopyTextInput = _resolveComponent("CopyTextInput");
    const _component_ShareStrip = _resolveComponent("ShareStrip");
    const _component_Modal = _resolveComponent("Modal");
    const _component_QRCodeModal = _resolveComponent("QRCodeModal");
    return (_openBlock(), _createElementBlock("div", null, [
        _createVNode(_component_Modal, {
            "custom-styles": { modal: { borderRadius: '0.25rem', maxWidth: '37.5rem' } },
            show: _ctx.show,
            "show-close-button": true,
            "mobile-drawer": true,
            onClosed: _cache[1] || (_cache[1] = ($event) => (_ctx.$emit('close')))
        }, {
            default: _withCtx(() => [
                _createElementVNode("h4", _hoisted_1, _toDisplayString(_ctx.simplified ? _ctx.t.shareLink : _ctx.t.shareableModalTitle), 1),
                _createElementVNode("div", {
                    class: _normalizeClass(["share-modal__content", { 'simplified': _ctx.simplified }])
                }, [
                    (!_ctx.simplified)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                            _createElementVNode("img", {
                                src: _ctx.shareData?.previewImageURL
                            }, null, 8, _hoisted_3)
                        ]))
                        : _createCommentVNode("", true),
                    (_ctx.shareData)
                        ? (_openBlock(), _createBlock(_component_CopyTextInput, {
                            key: 1,
                            class: "copy-input",
                            "copied-t": _ctx.t.copyText,
                            "copy-text": _ctx.shareData.url,
                            onCopied: _cache[0] || (_cache[0] = ($event) => (_ctx.$emit('share', 'copy_text')))
                        }, null, 8, ["copied-t", "copy-text"]))
                        : _createCommentVNode("", true),
                    (!_ctx.simplified)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                            (_ctx.shareData)
                                ? (_openBlock(), _createBlock(_component_ShareStrip, {
                                    key: 0,
                                    t: _ctx.t,
                                    "share-type": _ctx.shareType,
                                    "fb-app-id": _ctx.fbAppId,
                                    "share-data": _ctx.shareData,
                                    onShare: _ctx.handleShared
                                }, null, 8, ["t", "share-type", "fb-app-id", "share-data", "onShare"]))
                                : _createCommentVNode("", true)
                        ]))
                        : _createCommentVNode("", true)
                ], 2)
            ]),
            _: 1
        }, 8, ["show"]),
        (_ctx.shareData && _ctx.shareData.qrSRC)
            ? (_openBlock(), _createBlock(_component_QRCodeModal, {
                key: 0,
                "share-type": _ctx.shareType,
                t: _ctx.t,
                show: _ctx.showQRModal,
                "class-data": _ctx.classData,
                "instructor-data": _ctx.instructorData,
                "qr-src": _ctx.shareData.qrSRC,
                onClose: _cache[2] || (_cache[2] = ($event) => (_ctx.showQRModal = false))
            }, null, 8, ["share-type", "t", "show", "class-data", "instructor-data", "qr-src"]))
            : _createCommentVNode("", true)
    ]));
}
