import { computed, defineComponent } from 'vue';
import { validator } from '@ts/Util/i18n';
import ZButton from '@components/Core/ZButton.vue';
import Modal from '@components/Core/Modal.vue';
import { currentUserLocale } from '@ts/Util/i18n';
import StartSvg from '@bx-icons/solid/bxs-star.svg';
const localT = {
    shareClass: "Share Class",
    shareInstructor: "Share QR Code",
    printQRLabel: "Print",
    shareQRLabel: "Share",
    downloadQRLabel: "Download",
    cancel: "Cancel",
    scanThisQRCode: "Students can scan this code to sign up for your class or to be marked as attended.",
    scanThisQRCodeInstructor: "This QR code will link students to your instructor page.",
};
export var QRCodeShareType;
(function (QRCodeShareType) {
    QRCodeShareType["SHARE_CLASS"] = "share-class";
    QRCodeShareType["SHARE_INSTRUCTOR"] = "share-instructor";
})(QRCodeShareType || (QRCodeShareType = {}));
export default defineComponent({
    name: 'QRCodeModal',
    components: {
        ZButton,
        Modal,
        StartSvg,
    },
    props: {
        t: {
            type: Object,
            default: localT,
            validator: validator(localT)
        },
        show: {
            type: Boolean,
            default: false
        },
        shareType: {
            type: String,
            required: true
        },
        classData: {
            type: Object,
            default: (() => null),
        },
        instructorData: {
            type: Object,
            default: (() => null),
        },
        qrSrc: {
            type: String,
            required: true
        },
    },
    emits: ['close'],
    setup(props) {
        const printQRCode = () => {
            const imgHtml = new Image();
            imgHtml.src = props.qrSrc;
            const WindowObject = window.open('#', 'PrintQR', 'width=800,height=800,top=50,left=50,toolbars=no,scrollbars=yes,status=no,resizable=yes');
            const strHtml = `<html><head></head><body onload='window.print();window.close()'>${scanThisQRCode.value}<br><br><br>${imgHtml.outerHTML}</body></html>`;
            if (!WindowObject) {
                return;
            }
            WindowObject.document.writeln(strHtml);
            WindowObject.document.close();
            WindowObject.focus();
        };
        const modalTitle = computed(() => {
            return props.shareType === QRCodeShareType.SHARE_CLASS ? props.t.shareClass : props.t.shareInstructor;
        });
        const classDate = computed(() => {
            if (!props.classData.date) {
                return '';
            }
            const locale = currentUserLocale();
            const classDate = new Date(props.classData.date);
            const day = classDate.toLocaleDateString(locale, {
                weekday: 'long'
            });
            const date = classDate.toLocaleDateString(locale, {
                month: 'long', day: 'numeric'
            });
            const time = classDate.toLocaleTimeString(locale, {
                hour: 'numeric',
                minute: 'numeric',
            });
            return `${day}, ${date} @ ${time}`;
        });
        const scanThisQRCode = computed(() => {
            return props.shareType === QRCodeShareType.SHARE_CLASS ? props.t.scanThisQRCode : props.t.scanThisQRCodeInstructor;
        });
        return {
            printQRCode,
            QRCodeShareType,
            modalTitle,
            classDate,
            scanThisQRCode
        };
    },
});
